<template>
  <div :style="slidePosition" class="h-100 w-100 z-3 blueTableRef" style="">
    <img src="images/table-elements-TRIUMF.svg" class="position-absolute w-80 h-auto" width="180" height="168"
      aria-label="Table elements" style="bottom:-20%;" />
  </div>
</template>

<script>
import anime from "animejs"
import { slidePositionPlain } from "@/utils/slidePositioning"
export default {
  props: ["globalUnits", "slideIndex"],
  computed: {
    slidePosition() {
      //console.log("SLIDES INDEX", this.slideIndex)
      return slidePositionPlain(this.globalUnits, this.slideIndex)
    },
    innerPosition() {
      return (
        (this.globalUnits - this.slideIndex.start) /
        (this.slideIndex.end - this.slideIndex.start)
      )
    }
  },
  mounted() { },
  methods: {
    blueTableRef(percentage) {
      let animation = anime.timeline({
        autoplay: false,
        loop: false,
        endDelay: 200
      })

      animation
        .add({
          targets: ".blueTableRef",
          opacity: [0, 1],
          duration: 600,
          delay: 0,
          easing: "linear"
        })
        .add({
          targets: ".blueTableRef",
          opacity: [1, 0],
          duration: 400,
          delay: 0,
          easing: "linear"
        })

      animation.seek(animation.duration * percentage)
    },
    blueTableMove(percentage) {
      let animation = anime.timeline({
        autoplay: false,
        loop: false,
        endDelay: 0
      })

      animation
        .add({
          targets: ".blueTableRef",
          left: ["200%", "100%"],
          duration: 600,
          delay: 300,
          easing: "easeInOutQuad"
        })
        .add({
          targets: ".blueTableRef",
          left: ["100%", "-110%"],
          duration: 600,
          delay: 300,
          easing: "linear"
        })
      /* let progress =
        percentage <= 0.5 ? percentage * 2 : normalize(percentage, 0.5, 1, 1, 0)
      animation.seek(animation.duration * progress) */

      animation.seek(animation.duration * percentage)
    }
  },
  watch: {
    innerPosition() {
      this.blueTableRef(this.innerPosition)
    }
  }
}
</script>
<style lang="scss" scoped>
$star-color-A: white;
$star-color-B: rgba(255, 255, 255, 0.1);

#sky {
  transition: all 3s ease;
  transform: translateZ(0);
}
</style>
